'use client';

import { type StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image, { type ImageProps } from 'next/image';
import { useState } from 'react';

export type ImageWithFallbackProps = {
  blurImage?: string;
  fallbackSrc?: string | StaticImport;
} & ImageProps;

export const ImageWithFallback = ({
  blurImage,
  fallbackSrc,
  src,
  ...rest
}: ImageWithFallbackProps) => {
  const [imgSource, setImgSource] = useState(src || fallbackSrc || '');

  return (
    <Image
      {...rest}
      alt={rest.alt}
      blurDataURL={blurImage}
      onError={() => {
        if (fallbackSrc) {
          setImgSource(fallbackSrc);
        }
      }}
      placeholder={blurImage ? 'blur' : 'empty'}
      src={imgSource}
    />
  );
};
