'use client';

import { useEffect, useState } from 'react';

const getLastPrompted = (promptName: string) => {
  if (localStorage) {
    return localStorage.getItem(promptName);
  }

  return null;
};

const setLastPrompted = (promptName: string) => {
  if (localStorage) {
    localStorage.setItem(promptName, `${Date.now()}`);
  }
};

const getUserShouldBePrompted = (promptName: string, daysToWaitBeforePromptingAgain: number) => {
  const lastPrompt = getLastPrompted(promptName);

  if (!lastPrompt) return true;

  const diffInTime = Date.now() - Number(lastPrompt);
  const daysSinceLastPrompt = Math.round(diffInTime / (1000 * 3600 * 24));

  return Number.isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > daysToWaitBeforePromptingAgain;
};

export const useShouldShowInstallPrompt = (
  promptName: string,
  daysToWaitBeforePromptingAgain = 30
): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(false);

  const hideInstallPrompt = () => {
    setUserShouldBePromptedToInstall(false);
    setLastPrompted(promptName);
  };

  useEffect(() => {
    setUserShouldBePromptedToInstall(
      getUserShouldBePrompted(promptName, daysToWaitBeforePromptingAgain)
    );
  }, []);

  return [userShouldBePromptedToInstall, hideInstallPrompt];
};
